import React from "react";

import Default from "../../../layout/Default";
import ColoredButton from "../../ui/ColoredButton";
import PointsHeader from "../../blocks/PointsHeader";
import AnswerPopup from "../../popups/AnswerPopup";

export const QuestionsScreen = ({
  changeStatus,
  changeQuestion,
  changePoints,
  questionNumber,
  questions,
  status,
  points,
  i18n,
}) => {
  const answerHandler = (status) => {
    if (status === questions[questionNumber - 1].status) {
      changePoints(points + 1);
    }
    changeStatus(questions[questionNumber - 1].status);
  };

  const currentQuestion = questions[questionNumber - 1];

  return (
    <Default className="game-tinder-question" title="News Tinder">
      <div className="game-tinder-question__container">
        <div className="game-tinder-question__wrapper">
          <PointsHeader
            className="game-tinder-question__header"
            currentProgress={questionNumber}
            totalProgress={20}
            points={points}
          />

          <div className="game-tinder-question__body">
            {!status && (
              <>
                <div className="game-tinder-question__title">
                  <span className="game-tinder-question__font game-tinder-question__font--title font--title">
                    {i18n.t(`newstinder:${questionNumber}.title`)}
                  </span>
                </div>

                {currentQuestion.img && (
                  <img
                    src={currentQuestion.img}
                    className="game-tinder-question__image"
                    alt="image"
                  />
                )}

                <div className="game-tinder-question__text">
                  <span className="game-tinder-question__font game-tinder-question__font--text font--text">
                    {i18n.t(`newstinder:${questionNumber}.question`)}
                  </span>
                </div>
              </>
            )}

            {status === "fake" && (
              <FakePopup
                i18n={i18n}
                answer={i18n.t("newstinder:" + questionNumber + ".answer")}
                changeQuestion={changeQuestion}
                questionNumber={questionNumber}
              />
            )}
            {status === "really" && (
              <ReallyPopup
                i18n={i18n}
                answer={i18n.t("newstinder:" + questionNumber + ".answer")}
                changeQuestion={changeQuestion}
                questionNumber={questionNumber}
              />
            )}
          </div>

          <div className="game-tinder-question__buttons">
            <ColoredButton
              theme="pink"
              onClick={() => answerHandler("fake")}
              disabled={status === "really" || status === "fake"}
            >
              {i18n.t("newstinder:Fake")}
            </ColoredButton>

            <ColoredButton
              onClick={() => answerHandler("really")}
              disabled={status === "really" || status === "fake"}
            >
              {i18n.t("newstinder:True")}
            </ColoredButton>
          </div>
        </div>
      </div>
    </Default>
  );
};

const FakePopup = ({ answer, changeQuestion, questionNumber, i18n }) => (
  <AnswerPopup
    className="game-tinder-question__popup game-tinder-question__popup--fake"
    onClick={() => changeQuestion(questionNumber + 1)}
    buttonText={i18n.t("common:Buttons.next")}
  >
    <span className="game-tinder-question__font game-tinder-question__font--answer font--text">
      {answer}
    </span>
  </AnswerPopup>
);

const ReallyPopup = ({ answer, changeQuestion, questionNumber, i18n }) => (
  <AnswerPopup
    className="game-tinder-question__popup game-tinder-question__popup--true"
    isTrue
    onClick={() => changeQuestion(questionNumber + 1)}
    buttonText={i18n.t("common:Buttons.next")}
  >
    <span
      className="game-tinder-question__font game-tinder-question__font--answer font--text"
      dangerouslySetInnerHTML={{ __html: answer }}
    />
  </AnswerPopup>
);
