import React, { useEffect, useRef, useState } from "react";
import AnswerPopup from "../../popups/AnswerPopup";
import Default from "../../../layout/Default";
import PointsHeader from "../../blocks/PointsHeader";
import ColoredButton from "../../ui/ColoredButton";
import classNames from "classnames";

export const QuestionsScreen = ({
  i18n,
  changeScreen,
  changeStatus,
  changeQuestion,
  changePoints,
  questionNumber,
  questions,
  status,
  points,
}) => {
  const video = useRef(null);
  const [correctAnswered, setCorrectAnswered] = useState(false);
  useEffect(() => {
    video.current.volume = 0.5;
    video.current.play();
  }, []);

  const nextQuestion = () => {
    if (questionNumber % 2 === 0) {
      changeScreen("InfoScreen");
    }

    changeQuestion(questionNumber + 1);
    changeStatus("");
  };

  const answerHandler = (status) => {
    video.current.pause();
    if (status === questions[questionNumber - 1].status) {
      changePoints(points + 1);
    }
    setCorrectAnswered(status === questions[questionNumber - 1].status);
    changeStatus(questions[questionNumber - 1].status);
  };

  return (
    <Default className="game-deepfake-question" title="Original or not">
      <div className="game-deepfake-question__container">
        <div className="game-deepfake-question__wrapper">
          <PointsHeader
            className="game-deepfake-question__header"
            currentProgress={questionNumber}
            totalProgress={10}
            points={points}
          />

          <div
            className={classNames("game-deepfake-question__body", {
              "game-deepfake-question__body--popup":
                status === "really" || status === "fake",
            })}
          >
            <video
              controls="controls"
              autoPlay
              style={{ marginTop: "0" }}
              className="game-deepfake-question__video"
              key={questions[questionNumber - 1].video}
              ref={video}
            >
              <source
                src={questions[questionNumber - 1].video}
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
              />
            </video>

            <div className="game-deepfake-question__popup-wrapper">
              {status && (
                <Popup
                  i18n={i18n}
                  link={questions[questionNumber - 1].link}
                  date={i18n.t(`deepface:questions.${questionNumber}.date`)}
                  description={i18n.t(
                    `deepface:questions.${questionNumber}.description`,
                  )}
                  isTrue={status === "really"}
                  correctAnswered={correctAnswered}
                  nextQuestion={nextQuestion}
                  originalVideo={questions[questionNumber - 1].originalVideo}
                />
              )}
            </div>
          </div>

          <div className="game-deepfake-question__buttons">
            <ColoredButton
              className="game-deepfake-question__button"
              theme="pink"
              onClick={() => answerHandler("fake")}
              disabled={status === "really" || status === "fake"}
            >
              {i18n.t("deepface:notOriginal")}
            </ColoredButton>

            <ColoredButton
              className="game-deepfake-question__button"
              onClick={() => answerHandler("really")}
              disabled={status === "really" || status === "fake"}
            >
              {i18n.t("deepface:original")}
            </ColoredButton>
          </div>
        </div>
      </div>
    </Default>
  );
};

const FakePopup = ({
  i18n,
  date,
  description,
  link,
  originalVideo,
  nextQuestion,
  isTrue,
}) => (
  <AnswerPopup
    className="game-deepfake-question__popup game-deepfake-question__popup--fake"
    onClick={nextQuestion}
    isTrue={isTrue}
    buttonText={i18n.t("common:Buttons.next")}
  >
    <span className="game-deepfake-question__font game-deepfake-question__font--answer font--text">
      {description}
    </span>
    <br />
    <span className="game-deepfake-question__font game-deepfake-question__font--answer font--text">
      {date}
    </span>
    <br />
    <a href={link} target="_blank">
      <span className="game-deepfake-question__font game-deepfake-question__font--answer font--text">
        {link}
      </span>
    </a>
  </AnswerPopup>
);

const Popup = ({
  i18n,
  date,
  description,
  link,
  nextQuestion,
  isTrue,
  correctAnswered,
}) => (
  <AnswerPopup
    className="game-deepfake-question__popup game-deepfake-question__popup--true"
    isTrue={isTrue}
    correctAnswered={correctAnswered}
    onClick={nextQuestion}
    buttonText={i18n.t("common:Buttons.next")}
  >
    <span className="game-deepfake-question__font game-deepfake-question__font--answer font--text">
      {description}
    </span>
    <br />
    <span className="game-deepfake-question__font game-deepfake-question__font--answer font--text">
      {date}
    </span>
    <br />
    <a href={link} target="_blank">
      <span className="game-deepfake-question__font game-deepfake-question__font--answer font--text">
        {link}
      </span>
    </a>
  </AnswerPopup>
);
