import React, { useContext } from "react";
import classNames from "classnames";

import "./conveyor-game-over.scss";

import { LangContext } from "../../../../App";

import OutlinedButton from "../../../ui/OutlinedButton";

function ConveyorGameOver({ className = "", onClick }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "conveyor-game-over")}>
      <div className="conveyor-game-over__body">
        <div className="conveyor-game-over__text">
          <span className="conveyor-game-over__font font--text">
            {i18n.t("common:gameOver")}
          </span>
        </div>

        <OutlinedButton color="green" onClick={onClick}>
          {i18n.t("common:TryAgain")}
        </OutlinedButton>
      </div>
    </div>
  );
}

export default ConveyorGameOver;
