export default [
  // {
  //   title: "ReCAPTCHA",
  //   link: "/recaptcha",
  // },
  // {
  //   title: "Behind the screen",
  //   link: "/behind-the-screen",
  // },
  // {
  //   title: "News Tinder",
  //   link: "/news-tinder",
  // },
  // {
  //   title: "Hold your breath (COVID-19)",
  //   link: "/hold-breath",
  // },
  // {
  //   title: "Unlock my phone",
  //   link: "/passwords",
  // },
  // {
  //   title: "Take a moment, take a quiz",
  //   link: "/take-a-quiz",
  // },
  // {
  //   title: "AI VERSUS",
  //   link: "/aiversus",
  // },
  // {
  //   title: "Find Waldo",
  //   link: "/find-waldo",
  // },
  // {
  //   title: "Original or not",
  //   link: "/original-or-not",
  // },
  // {
  //   title: "Forgote your password?",
  //   link: "/forgote-pass-exponat",
  // },
  // {
  //   title: "Wallpaper Project",
  //   link: "/wallpaper-exponat",
  // },
  // {
  //   title: "Prove me wrong",
  //   link: "/prove-me-wrong",
  // },
  // {
  //   title: "Conveyor",
  //   link: "/conveyor",
  //   hideBorder: true,
  // },
  {
    title: "Navigate in media signs",
    link: "/navigate-in-media-signs",
    hideBorder: true,
  },
];
