import React, { useContext } from "react";
import classNames from "classnames";

import "./game-navigate-explanation.scss";

import { LangContext } from "../../../../App";
import OutlinedButton from "../../../ui/OutlinedButton";
import Signs from "../blocks/Signs";
import { signsLevel1 } from "../../levels/signs";

function ExplanationScreen({ className = "", onClick }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "game-navigate-explanation")}>
      <div className="game-navigate-explanation__content">
        <div className="game-navigate-explanation__text">
          <span className="game-navigate-explanation__font font--text">
            {i18n.t("navigatemedia:explanation")}
          </span>
        </div>

        <OutlinedButton
          className="game-navigate-explanation__button"
          onClick={onClick}
        >
          {i18n.t("common:Buttons.start")}
        </OutlinedButton>
      </div>

      <Signs
        className="game-navigate-explanation__signs"
        items={signsLevel1}
        size="medium"
        disableClick
      />
    </div>
  );
}

export default ExplanationScreen;
