import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./assets/scss/main.scss";

import NewsTinder from "./components/NewsTinder/NewsTinder";
import HomePage from "./components/Home/HomePage";
import HoldBreath from "./components/HoldBreath/HoldBreath";
import ReCaptcha from "./components/ReCaptcha/ReCaptcha";
import { AIVersus } from "./components/AIVersus/AIVersus";
import { Passwords } from "./components/Passwords/Passwords";
import TakeQuiz from "./components/TakeQuiz/TakeQuiz";
import BehindScreen from "./components/BehindScreen/BehindScreen";
import { Exponat } from "./components/Exponat";
import { FindWaldo } from "./components/FindWaldo/FindWaldo";
import { WallpaperExponat } from "./components/WallpaperExponat";
import DeepFake from "./components/DeepFake/DeepFake";
import ProveMeWrong from "./components/ProveMeWrong/ProveMeWrong";
import Conveyor from "./components/Conveyor/Conveyor";
import NavigateInMediaSigns from "./components/NavigateInMediaSigns/NavigateInMediaSigns";

export const LangContext = React.createContext("");

const App = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  //Get i18next hook
  const { i18n } = useTranslation([
    "translation",
    "common",
    "versusData",
    "recaptcha",
    "newstinder",
    "holdbreath",
    "quizgame",
    "passgame",
    "behindscreen",
    "waldogame",
    "deepface",
    "provemewrong",
    "conveyor",
    "navigatemedia",
  ]);

  return (
    <LangContext.Provider value={i18n}>
      <Switch>
        <Redirect exact from="/" to="/uk" />
        <Route exact path="/:locale" component={HomePage} />
        <Route exact path="/:locale/news-tinder" component={NewsTinder} />
        <Route exact path="/:locale/hold-breath" component={HoldBreath} />
        <Route exact path="/:locale/recaptcha" component={ReCaptcha} />
        <Route exact path="/:locale/aiversus" component={AIVersus} />
        <Route exact path="/:locale/passwords" component={Passwords} />
        <Route exact path="/:locale/take-a-quiz" component={TakeQuiz} />
        <Route
          exact
          path="/:locale/behind-the-screen"
          component={BehindScreen}
        />
        <Route exact path="/:locale/forgote-pass-exponat" component={Exponat} />
        <Route exact path="/:locale/find-waldo" component={FindWaldo} />
        <Route
          exact
          path="/:locale/wallpaper-exponat"
          component={WallpaperExponat}
        />
        <Route exact path="/:locale/original-or-not" component={DeepFake} />
        <Route exact path="/:locale/prove-me-wrong" component={ProveMeWrong} />
        <Route exact path="/:locale/conveyor" component={Conveyor} />
        <Route
          exact
          path="/:locale/navigate-in-media-signs"
          component={NavigateInMediaSigns}
        />
      </Switch>
    </LangContext.Provider>
  );
};
export default App;
