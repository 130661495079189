import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import "./game-navigate-round.scss";

import settings from "../../config/settings";

import RoundModal from "../blocks/RoundModal";
import RoundLabel from "../blocks/RoundLabel";
import LifesLabel from "../../../blocks/LifesLabel";
import Signs from "../blocks/Signs";
import { LangContext } from "../../../../App";
import OutlinedButton from "../../../ui/OutlinedButton";
import Timer from "../blocks/Timer";

function RoundScreen({
  className = "",
  showModal = true,
  showSigns = false,
  showEndGame = false,
  onRoundStart,
  handleAnswer,
  restartRound,
  attempts,
  level,
  stage,
  signs,
  timer,
  stageData,
}) {
  const i18n = useContext(LangContext);

  const [clickedAnswerId, setClickedAnswerId] = useState(null);
  const [answersToShow, setAnswersToShow] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [canAnswer, setCanAnswer] = useState(true);

  const onAnswer = (id) => {
    if (showEndGame || !canAnswer) return;

    setCanAnswer(false);

    setClickedAnswerId(id);
    setAnswersToShow([...answersToShow, stageData.answerId]);

    handleAnswer(id === stageData.answerId);

    setShowAnswer(true);

    setTimeout(() => {
      setShowAnswer(false);
      setCanAnswer(true);
    }, settings.CHANGE_STAGE_DELAY - 500);
  };

  useEffect(() => {
    setClickedAnswerId(null);
  }, [stageData]);

  return (
    <div className={classNames(className, "game-navigate-round")}>
      {showModal ? (
        <RoundModal
          className="game-navigate-round__modal"
          level={level}
          onClick={() => {
            setAnswersToShow([]);
            onRoundStart(level);
          }}
        />
      ) : (
        <>
          <div className="game-navigate-round__head">
            <RoundLabel level={level} />

            {showSigns ? (
              <Timer time={timer} />
            ) : (
              <LifesLabel
                className="game-navigate-round__lifes"
                lifes={attempts}
              />
            )}
          </div>

          {showSigns && (
            <Signs
              className="game-navigate-round__signs"
              items={signs}
              size="medium"
              showAll
              disableClick
            />
          )}

          {!showSigns && (
            <div className="game-navigate-round__question">
              <div className="game-navigate-round__content">
                <div className="game-navigate-round__title">
                  <span className="game-navigate-round__font game-navigate-round__font--title font--title">
                    {stage + 1}/6
                    <br />
                    {i18n.t(stageData.title)}
                  </span>
                </div>

                <div className="game-navigate-round__text">
                  <span
                    className="game-navigate-round__font game-navigate-round__font--text font--text"
                    dangerouslySetInnerHTML={{ __html: i18n.t(stageData.text) }}
                  />
                </div>

                {showEndGame && (
                  <div className="game-navigate-round__end-wrapper">
                    <div className="game-navigate-round__end">
                      <div className="game-navigate-round__end-text">
                        <span className="game-navigate-round__font game-navigate-round__font--end font--text">
                          {i18n.t("common:gameOver")}
                        </span>
                      </div>

                      <OutlinedButton
                        className="game-navigate-round__end-button"
                        onClick={restartRound}
                      >
                        {i18n.t("common:TryAgain")}
                      </OutlinedButton>
                    </div>
                  </div>
                )}
              </div>

              <Signs
                className="game-navigate-round__answers"
                items={signs}
                size="medium"
                correctId={stageData.answerId}
                showId={clickedAnswerId}
                answersToShow={answersToShow}
                showAnswer={showAnswer}
                onClick={onAnswer}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RoundScreen;
